import { createFeatureSelector, createSelector } from '@ngrx/store';

import { APP_CONFIG_FEATURE_NAME, AppConfigState } from './app-config.model';
import { ContactPointDto } from '../api/models';

export const selectAppConfigFeature = createFeatureSelector<AppConfigState>(APP_CONFIG_FEATURE_NAME);

export const selectFeats = createSelector(selectAppConfigFeature, (appConfig: AppConfigState) => appConfig.feats);

export const selectCompanyName = createSelector(
  selectAppConfigFeature,
  (appConfig: AppConfigState) => appConfig.companyName
);

export const selectReady = createSelector(selectAppConfigFeature, (appConfig: AppConfigState) => appConfig._id !== '');

export const selectContactEmail = createSelector(selectAppConfigFeature, (appConfig: AppConfigState) =>
  appConfig.contactPoint.find((contactPoint: ContactPointDto) => contactPoint.type === 'EMAIL')
);

export const selectContactPhoneNumber = createSelector(selectAppConfigFeature, (appConfig: AppConfigState) =>
  appConfig.contactPoint.find((contactPoint: ContactPointDto) => contactPoint.type === 'PHONE_NUMBER')
);

export const selectPermissionMatrix = createSelector(
  selectAppConfigFeature,
  (state: AppConfigState) => state.permissionMatrix
);
export const selectPermissionMatrixLoaded = createSelector(
  selectAppConfigFeature,
  (state: AppConfigState) => state.permissionMatrix !== null
);
