import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AccountState } from './account.feature';
import { ProductCode } from '../api/models';
import { AccountDto } from '../api/models/account-dto';
import { ResourceType } from '../api/models/resource-type';

export const selectAccountFeature = createFeatureSelector<AccountState>('account');

export const selectAccount = createSelector(
  selectAccountFeature,
  (state: AccountState): AccountDto | null => state.account
);
export const selectReady = createSelector(selectAccountFeature, (state: AccountState): boolean => state.ready);

//LICENSE
export const selectAccountCurrentLicense = createSelector(selectAccount, account => account?.currentLicense);
export const selectAccountCurrentLicenseProductCode = createSelector(
  selectAccountCurrentLicense,
  license => license?.productCode
);
export const selectAccountCurrentLicenseValidUntil = createSelector(
  selectAccountCurrentLicense,
  license => license?.validUntil
);
export const selectHasValidLicense = createSelector(selectAccountCurrentLicenseValidUntil, validUntil => {
  if (!validUntil) return false;
  const validUntilDate = new Date(validUntil);
  const nowUtc = Date.now();
  const validUntilUtc = Date.UTC(
    validUntilDate.getUTCFullYear(),
    validUntilDate.getUTCMonth(),
    validUntilDate.getUTCDate(),
    validUntilDate.getUTCHours(),
    validUntilDate.getUTCMinutes(),
    validUntilDate.getUTCSeconds()
  );

  return validUntilUtc > nowUtc;
});

export const selectNoLicense = createSelector(selectHasValidLicense, hasValidLicense => !hasValidLicense);
export const selectHasTeamPremiumLicense = createSelector(
  selectAccount,
  account =>
    account?.currentLicense.productCode &&
    [ProductCode.Premium, ProductCode.Team].includes(account?.currentLicense.productCode)
);

//RESOURCES
export const selectAccountResources = createSelector(selectAccount, account => account?.resources);
export const selectAccountSmsValue = createSelector(
  selectAccountResources,
  resources => resources?.filter(resource => resource.type === ResourceType.Sms)[0]?.value
);
export const selectAccountDataValue = createSelector(
  selectAccountResources,
  resources => resources?.filter(resource => resource.type === ResourceType.Data)[0]?.value
);
export const selectAccountUsersValue = createSelector(
  selectAccountResources,
  resources => resources?.filter(resource => resource.type === ResourceType.Users)[0]?.value
);
export const selectAccountMonthlyAppointmentsValue = createSelector(
  selectAccountResources,
  resources => resources?.filter(resource => resource.type === ResourceType.MonthlyAppointments)[0]?.value
);
export const selectAccountPatientsValue = createSelector(
  selectAccountResources,
  resources => resources?.filter(resource => resource.type === ResourceType.Patients)[0]?.value
);
export const selectAccountOwnersValue = createSelector(
  selectAccountResources,
  resources => resources?.filter(resource => resource.type === ResourceType.Owners)[0]?.value
);
