import { Injectable } from '@angular/core';
import { ResolverHelper } from '@kate-fizjo/practice-shared/tools';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';

import { CalendarActions } from './state/calendar.actions';
import { CalendarEventsRange } from './state/calendar.model';
import { selectReady } from './state/calendar.selectors';

@Injectable()
export class CalendarResolver extends ResolverHelper {
  constructor(store: Store) {
    super(store);
  }

  public override resolve(): Observable<any> {
    const dt: DateTime = DateTime.now();
    const range: CalendarEventsRange = {
      end: dt.endOf('month').toISODate() || '',
      start: dt.startOf('month').toISODate() || '',
    };

    return this.store.select(selectReady).pipe(this.helpResolve(CalendarActions.fetch(range)));
  }
}
