import { CommonModule } from '@angular/common';
import { Component, computed, inject, Signal } from '@angular/core';
import { selectHasValidLicense } from '@fizjo-pro/data-account';
import { selectReady as selectAccountReady } from '@fizjo-pro/data-account';
import { selectInitials, selectIsAuthenticated } from '@fizjo-pro/data-auth';
import { SwitchAppUserComponent } from '@fizjo-pro/ui-app-user';
import { MessagesNotificationComponent } from '@fizjo-pro/ui-messages';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { MenuItem, SharedModule } from 'primeng/api';
import { MenubarModule } from 'primeng/menubar';

import { MenuService } from './menu.service';

@Component({
  selector: 'fizjo-pro-app-layout',
  standalone: true,
  imports: [
    CommonModule,
    MenubarModule,
    MessagesNotificationComponent,
    SharedModule,
    SwitchAppUserComponent,
    TranslateModule,
  ],
  templateUrl: './app-layout.component.html',
  styleUrl: './app-layout.component.scss',
  providers: [MenuService],
})
export class AppLayoutComponent {
  #store: Store = inject(Store);
  #menuService: MenuService = inject(MenuService);
  #hasValidLicense: Signal<boolean> = this.#store.selectSignal(selectHasValidLicense);
  #selectAccountReady: Signal<boolean> = this.#store.selectSignal(selectAccountReady);

  protected hasExpiredLicenseBannerVisible = computed(() => !this.#hasValidLicense() && this.#selectAccountReady());
  protected authenticated: Signal<boolean> = this.#store.selectSignal(selectIsAuthenticated);
  protected allMenuItems: Signal<MenuItem[]> = this.#menuService.allMenuItems;
  protected initials: Signal<string | null> = this.#store.selectSignal(selectInitials);
}
