import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppDomainService, X_TENANT_ID } from '@fizjo-pro/shared/util-app-domain';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { map, switchMap, tap } from 'rxjs/operators';

import * as PatientActions from './patient.actions';
import { PatientDto } from '../api/models';
import { PatientService } from '../api/services/patient.service';

@Injectable()
export class PatientEffects {
  #actions$: Actions = inject(Actions);
  #patientService: PatientService = inject(PatientService);
  #messageService: MessageService = inject(MessageService);
  #router: Router = inject(Router);
  #appDomainService: AppDomainService = inject(AppDomainService);
  #ref!: DynamicDialogRef | undefined;
  readonly #tenantIdParam: { 'x-tenant-id': string } = { [X_TENANT_ID]: this.#appDomainService.tenantId };

  public loadPatient$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(PatientActions.loadPatients),
      switchMap(() => this.#patientService.patientControllerReadAll()),
      map((patients: PatientDto[]) => PatientActions.loadPatientsSuccess({ patients }))
    );
  });

  public savePatient$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(PatientActions.savePatient),
      switchMap(action =>
        this.#patientService.patientControllerCreate({
          body: action.payload,
          ...this.#tenantIdParam,
        })
      ),
      map((patient: PatientDto) => PatientActions.savePatientSuccess({ patient }))
    );
  });

  public savePatientFromDialog$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(PatientActions.savePatientFromDialog),
      tap(action => {
        this.#ref = action.ref;
      }),
      switchMap(action =>
        this.#patientService.patientControllerCreate({
          body: action.payload,
          ...this.#tenantIdParam,
        })
      ),
      map((patient: PatientDto) => PatientActions.savePatientSuccessAndCloseDialog({ patient })),
      tap(() => {
        this.#ref?.close();
      })
    );
  });

  public savePatientSuccess$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(PatientActions.savePatientSuccess),
      switchMap(action => this.#router.navigate(['/patients', action.patient._id])),
      map(() => PatientActions.message({ message: 'Zapisano dane pacjenta', severity: 'success' }))
    );
  });

  public updatePatient$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(PatientActions.updatePatient),
      switchMap(action =>
        this.#patientService.patientControllerUpdate({
          body: action.payload,
          patientId: action.patientId,
        })
      ),
      map((patient: PatientDto) => PatientActions.savePatientSuccess({ patient }))
    );
  });

  public patchPatientOwner$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(PatientActions.patchPatientOwner),
      switchMap(action =>
        this.#patientService.patientControllerPatchOwner({
          body: { owner: action.owner },
          patientId: action.patientId,
        })
      ),
      map((patient: PatientDto) => PatientActions.patchPatientOwnerSuccess({ patient }))
    );
  });

  public deletePatientOwner$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(PatientActions.deletePatientOwner),
      switchMap(action =>
        this.#patientService.patientControllerDeleteOwner({
          patientId: action.patientId,
        })
      ),
      map((patient: PatientDto) => PatientActions.patchPatientOwnerSuccess({ patient }))
    );
  });

  public patchPatientOwnerSuccess$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(PatientActions.patchPatientOwnerSuccess),
      map(() => PatientActions.message({ message: 'Zmieniono właściciela zwierzęcia', severity: 'success' }))
    );
  });

  public showMessage$ = createEffect(
    () => {
      return this.#actions$.pipe(
        ofType(PatientActions.message),
        tap(action => {
          this.#messageService.add({ detail: action.message, severity: action.severity, summary: 'Pacjent' });
        })
      );
    },
    { dispatch: false }
  );
}
