/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EquipmentDto } from '../models/equipment-dto';
import { EquipmentPayloadDto } from '../models/equipment-payload-dto';
import { EquipmentStateDto } from '../models/equipment-state-dto';

@Injectable({
  providedIn: 'root',
})
export class EquipmentService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation equipmentControllerReadAll
   */
  static readonly EquipmentControllerReadAllPath = '/api/equipment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equipmentControllerReadAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentControllerReadAll$Response(params: {
    'x-tenant-id': string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<EquipmentDto>>> {

    const rb = new RequestBuilder(this.rootUrl, EquipmentService.EquipmentControllerReadAllPath, 'get');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EquipmentDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equipmentControllerReadAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentControllerReadAll(params: {
    'x-tenant-id': string;
    context?: HttpContext
  }
): Observable<Array<EquipmentDto>> {

    return this.equipmentControllerReadAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EquipmentDto>>) => r.body as Array<EquipmentDto>)
    );
  }

  /**
   * Path part for operation equipmentControllerCreate
   */
  static readonly EquipmentControllerCreatePath = '/api/equipment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equipmentControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  equipmentControllerCreate$Response(params: {
    'x-tenant-id': string;
    context?: HttpContext
    body: EquipmentPayloadDto
  }
): Observable<StrictHttpResponse<EquipmentDto>> {

    const rb = new RequestBuilder(this.rootUrl, EquipmentService.EquipmentControllerCreatePath, 'post');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EquipmentDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equipmentControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  equipmentControllerCreate(params: {
    'x-tenant-id': string;
    context?: HttpContext
    body: EquipmentPayloadDto
  }
): Observable<EquipmentDto> {

    return this.equipmentControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<EquipmentDto>) => r.body as EquipmentDto)
    );
  }

  /**
   * Path part for operation equipmentControllerUpdate
   */
  static readonly EquipmentControllerUpdatePath = '/api/equipment/{equipmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equipmentControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  equipmentControllerUpdate$Response(params: {

    /**
     * The equipment MongoDB identifier
     */
    equipmentId: string;
    context?: HttpContext
    body: EquipmentPayloadDto
  }
): Observable<StrictHttpResponse<EquipmentDto>> {

    const rb = new RequestBuilder(this.rootUrl, EquipmentService.EquipmentControllerUpdatePath, 'put');
    if (params) {
      rb.path('equipmentId', params.equipmentId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EquipmentDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equipmentControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  equipmentControllerUpdate(params: {

    /**
     * The equipment MongoDB identifier
     */
    equipmentId: string;
    context?: HttpContext
    body: EquipmentPayloadDto
  }
): Observable<EquipmentDto> {

    return this.equipmentControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<EquipmentDto>) => r.body as EquipmentDto)
    );
  }

  /**
   * Path part for operation equipmentControllerDelete
   */
  static readonly EquipmentControllerDeletePath = '/api/equipment/{equipmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equipmentControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentControllerDelete$Response(params: {

    /**
     * The equipment MongoDB identifier
     */
    equipmentId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EquipmentDto>> {

    const rb = new RequestBuilder(this.rootUrl, EquipmentService.EquipmentControllerDeletePath, 'delete');
    if (params) {
      rb.path('equipmentId', params.equipmentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EquipmentDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equipmentControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentControllerDelete(params: {

    /**
     * The equipment MongoDB identifier
     */
    equipmentId: string;
    context?: HttpContext
  }
): Observable<EquipmentDto> {

    return this.equipmentControllerDelete$Response(params).pipe(
      map((r: StrictHttpResponse<EquipmentDto>) => r.body as EquipmentDto)
    );
  }

  /**
   * Path part for operation equipmentControllerPatchEquipmentState
   */
  static readonly EquipmentControllerPatchEquipmentStatePath = '/api/equipment/{equipmentId}/active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equipmentControllerPatchEquipmentState()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  equipmentControllerPatchEquipmentState$Response(params: {

    /**
     * The equipment MongoDB identifier
     */
    equipmentId: string;
    context?: HttpContext
    body: EquipmentStateDto
  }
): Observable<StrictHttpResponse<EquipmentDto>> {

    const rb = new RequestBuilder(this.rootUrl, EquipmentService.EquipmentControllerPatchEquipmentStatePath, 'patch');
    if (params) {
      rb.path('equipmentId', params.equipmentId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EquipmentDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equipmentControllerPatchEquipmentState$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  equipmentControllerPatchEquipmentState(params: {

    /**
     * The equipment MongoDB identifier
     */
    equipmentId: string;
    context?: HttpContext
    body: EquipmentStateDto
  }
): Observable<EquipmentDto> {

    return this.equipmentControllerPatchEquipmentState$Response(params).pipe(
      map((r: StrictHttpResponse<EquipmentDto>) => r.body as EquipmentDto)
    );
  }

}
