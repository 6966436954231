import { EnvironmentProviders, importProvidersFrom, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { createFeature, provideState } from '@ngrx/store';

import { ApiModule } from './api/api.module';
import { CalendarFacade } from './calendar.facade';
import { CalendarResolver } from './calendar.resolver';
import { CalendarEffects } from './state/calendar.effects';
import { CALENDAR_FEATURE_KEY } from './state/calendar.model';
import { calendarReducer } from './state/calendar.reducers';

export const calendarProviders: (Provider | EnvironmentProviders)[] = [
  importProvidersFrom(ApiModule),
  provideState(
    createFeature({
      name: CALENDAR_FEATURE_KEY,
      reducer: calendarReducer,
    })
  ),
  provideEffects([CalendarEffects]),
  CalendarFacade,
  CalendarResolver,
];
