<div [formGroup]="phoneNumberForm" class="fizjo-pro-phone-number flex flex-row relative">
  <p-dropdown [options]="countryCodes" optionValue="value" formControlName="countryCode">
    <ng-template pTemplate="option" let-countryCode>
      <span>+{{countryCode.value}}&nbsp;{{ countryCode.label }}</span>
    </ng-template>
    <ng-template pTemplate="selectedItem" let-countryCode>
      <span>+{{countryCode.value}}</span>
    </ng-template>
  </p-dropdown>
  <input pInputText formControlName="phoneNumber" maxlength="9" class="flex-grow-1" />
  <label class="text-xs label-required">{{label}}</label>
</div>
