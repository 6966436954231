import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { inject, Inject, Injectable } from '@angular/core';
import { ApiConfigurationToken } from '@kate-fizjo/practice-shared/environment';
import { CookieService } from 'ngx-cookie';
import { Observable, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

export const X_TENANT_ID = 'x-tenant-id';

@Injectable({
  providedIn: 'root',
})
export class AppDomainService {
  #rootUrl: string = inject(ApiConfigurationToken).rootUrl;

  public get appDomain(): string {
    const hostname: string = this.document.location.hostname;

    return /\.fizjo\.pro$/.test(hostname) ? hostname.split('.')[0] : 'fizjopro-dev';
  }

  public get tenantId(): string {
    const tenantId = this.cookies.get('x-tenant-id') || null;

    if (tenantId === null) {
      this.fetchTenantId();
    }

    return tenantId || '';
  }

  constructor(
    @Inject(DOCUMENT) private document: any,
    private cookies: CookieService,
    private http: HttpClient
  ) {}

  public tenantId$(): Observable<string> {
    const xTenantId: string | undefined = this.cookies.get(X_TENANT_ID);

    if (xTenantId) {
      return of(xTenantId);
    }

    return this.http.get<{ tenantId: string } | null>(`${this.#rootUrl}/api/account/${this.appDomain}`).pipe(
      map((response: { tenantId: string } | null) => response?.tenantId || null),
      tap((tenantId: string | null) => {
        if (tenantId) {
          this.cookies.put(X_TENANT_ID, tenantId || '');
        }
      }),
      filter((tenantId: string | null) => tenantId !== null),
      map((tenantId: string | null) => tenantId as string)
    );
  }

  public fetchTenantId(): void {
    this.tenantId$().subscribe();
  }
}
