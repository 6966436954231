/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GdprSettingsDto } from '../models/gdpr-settings-dto';
import { GdprSettingsEmailContentDto } from '../models/gdpr-settings-email-content-dto';
import { GdprSettingsPayloadDto } from '../models/gdpr-settings-payload-dto';
import { GdprSettingsPayloadSingleDto } from '../models/gdpr-settings-payload-single-dto';
import { GdprSettingsTermsDto } from '../models/gdpr-settings-terms-dto';

@Injectable({
  providedIn: 'root',
})
export class GdprService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation gdprSettingsControllerReadOne
   */
  static readonly GdprSettingsControllerReadOnePath = '/api/gdpr-settings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gdprSettingsControllerReadOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  gdprSettingsControllerReadOne$Response(params: {
    'x-tenant-id': string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GdprSettingsDto>> {

    const rb = new RequestBuilder(this.rootUrl, GdprService.GdprSettingsControllerReadOnePath, 'get');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GdprSettingsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `gdprSettingsControllerReadOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  gdprSettingsControllerReadOne(params: {
    'x-tenant-id': string;
    context?: HttpContext
  }
): Observable<GdprSettingsDto> {

    return this.gdprSettingsControllerReadOne$Response(params).pipe(
      map((r: StrictHttpResponse<GdprSettingsDto>) => r.body as GdprSettingsDto)
    );
  }

  /**
   * Path part for operation gdprSettingsControllerUpdate
   */
  static readonly GdprSettingsControllerUpdatePath = '/api/gdpr-settings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gdprSettingsControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gdprSettingsControllerUpdate$Response(params: {
    'x-tenant-id': string;
    context?: HttpContext
    body: GdprSettingsPayloadDto
  }
): Observable<StrictHttpResponse<GdprSettingsDto>> {

    const rb = new RequestBuilder(this.rootUrl, GdprService.GdprSettingsControllerUpdatePath, 'put');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GdprSettingsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `gdprSettingsControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gdprSettingsControllerUpdate(params: {
    'x-tenant-id': string;
    context?: HttpContext
    body: GdprSettingsPayloadDto
  }
): Observable<GdprSettingsDto> {

    return this.gdprSettingsControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<GdprSettingsDto>) => r.body as GdprSettingsDto)
    );
  }

  /**
   * Path part for operation gdprSettingsControllerCreate
   */
  static readonly GdprSettingsControllerCreatePath = '/api/gdpr-settings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gdprSettingsControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gdprSettingsControllerCreate$Response(params: {
    'x-tenant-id': string;
    context?: HttpContext
    body: GdprSettingsPayloadDto
  }
): Observable<StrictHttpResponse<GdprSettingsDto>> {

    const rb = new RequestBuilder(this.rootUrl, GdprService.GdprSettingsControllerCreatePath, 'post');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GdprSettingsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `gdprSettingsControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gdprSettingsControllerCreate(params: {
    'x-tenant-id': string;
    context?: HttpContext
    body: GdprSettingsPayloadDto
  }
): Observable<GdprSettingsDto> {

    return this.gdprSettingsControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<GdprSettingsDto>) => r.body as GdprSettingsDto)
    );
  }

  /**
   * Path part for operation gdprSettingsControllerUpdateSingle
   */
  static readonly GdprSettingsControllerUpdateSinglePath = '/api/gdpr-settings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gdprSettingsControllerUpdateSingle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gdprSettingsControllerUpdateSingle$Response(params: {
    'x-tenant-id': string;
    context?: HttpContext
    body: GdprSettingsPayloadSingleDto
  }
): Observable<StrictHttpResponse<GdprSettingsDto>> {

    const rb = new RequestBuilder(this.rootUrl, GdprService.GdprSettingsControllerUpdateSinglePath, 'patch');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GdprSettingsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `gdprSettingsControllerUpdateSingle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gdprSettingsControllerUpdateSingle(params: {
    'x-tenant-id': string;
    context?: HttpContext
    body: GdprSettingsPayloadSingleDto
  }
): Observable<GdprSettingsDto> {

    return this.gdprSettingsControllerUpdateSingle$Response(params).pipe(
      map((r: StrictHttpResponse<GdprSettingsDto>) => r.body as GdprSettingsDto)
    );
  }

  /**
   * Path part for operation gdprSettingsControllerReadSettings
   */
  static readonly GdprSettingsControllerReadSettingsPath = '/api/gdpr-settings/email';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gdprSettingsControllerReadSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  gdprSettingsControllerReadSettings$Response(params: {
    'x-tenant-id': string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GdprSettingsEmailContentDto>> {

    const rb = new RequestBuilder(this.rootUrl, GdprService.GdprSettingsControllerReadSettingsPath, 'get');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GdprSettingsEmailContentDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `gdprSettingsControllerReadSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  gdprSettingsControllerReadSettings(params: {
    'x-tenant-id': string;
    context?: HttpContext
  }
): Observable<GdprSettingsEmailContentDto> {

    return this.gdprSettingsControllerReadSettings$Response(params).pipe(
      map((r: StrictHttpResponse<GdprSettingsEmailContentDto>) => r.body as GdprSettingsEmailContentDto)
    );
  }

  /**
   * Path part for operation gdprSettingsControllerReadTerms
   */
  static readonly GdprSettingsControllerReadTermsPath = '/api/gdpr-settings/terms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gdprSettingsControllerReadTerms()` instead.
   *
   * This method doesn't expect any request body.
   */
  gdprSettingsControllerReadTerms$Response(params: {
    'x-tenant-id': string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GdprSettingsTermsDto>> {

    const rb = new RequestBuilder(this.rootUrl, GdprService.GdprSettingsControllerReadTermsPath, 'get');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GdprSettingsTermsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `gdprSettingsControllerReadTerms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  gdprSettingsControllerReadTerms(params: {
    'x-tenant-id': string;
    context?: HttpContext
  }
): Observable<GdprSettingsTermsDto> {

    return this.gdprSettingsControllerReadTerms$Response(params).pipe(
      map((r: StrictHttpResponse<GdprSettingsTermsDto>) => r.body as GdprSettingsTermsDto)
    );
  }

}
