import { Routes } from '@angular/router';
import { Permission } from '@fizjo-pro/data-auth';
import { statsProviders, statsResolver } from '@fizjo-pro/data-stats';
import { equipmentRoutes } from '@fizjo-pro/feat-equipment';
import { billingDataProviders, BillingDataResolver } from '@fizjo-pro/settings/data-billing-data';
import { formTemplateProviders } from '@fizjo-pro/settings/data-form-template';
import { gdprProviders, GdprResolver } from '@fizjo-pro/settings/data-gdpr';
import { appUsersProviders } from '@fizjo-pro/settings/data-user';
import { formTemplateRoutes } from '@fizjo-pro/settings/feat-form-template';
import { RoleGuard } from '@fizjo-pro/util-auth';
import { appUsersRoutes } from '@kate-fizjo/settings/app-users';
import { templateRoutes } from '@kate-fizjo/settings/procedure-template';
import { userRoutes } from '@kate-fizjo/settings/user';

export const settingsRoutes: Routes = [
  {
    loadComponent: () => import('./settings-main/settings-main.component').then(m => m.SettingsMainComponent),
    path: 'stats',
    providers: [...statsProviders],
    resolve: {
      stats: statsResolver,
    },
  },
  {
    children: equipmentRoutes,
    path: 'equipment',
  },
  {
    children: templateRoutes,
    path: 'procedures',
  },
  {
    children: formTemplateRoutes,
    path: 'form-template',
    providers: [...formTemplateProviders],
  },
  {
    children: userRoutes,
    path: 'user',
    providers: [...appUsersProviders],
  },
  {
    canActivate: [RoleGuard],
    data: {
      permission: Permission.CompanyDataEditor,
    },
    loadComponent: () => import('@kate-fizjo/settings/app-settings').then(m => m.AppSettingsComponent),
    path: 'app-settings',
    providers: [...billingDataProviders],
    resolve: {
      billingData: BillingDataResolver,
    },
  },
  {
    canActivate: [RoleGuard],
    data: {
      permission: Permission.CompanyDataEditor,
    },
    loadComponent: () => import('@fizjo-pro/settings/feat-rodo').then(m => m.GdprSettingsComponent),
    path: 'gdpr',
    providers: gdprProviders,
    resolve: {
      gdprSettings: GdprResolver,
    },
  },
  {
    canActivate: [RoleGuard],
    children: appUsersRoutes,
    data: {
      permission: Permission.UserEditor,
    },
    path: 'app-users',
  },
  {
    path: '**',
    redirectTo: 'stats',
  },
];
