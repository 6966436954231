import { HttpContextToken, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject, InjectionToken } from '@angular/core';
import { AppDomainService, X_TENANT_ID } from '@fizjo-pro/shared/util-app-domain';
import { CookieService } from 'ngx-cookie';
import { Observable } from 'rxjs';

export const CONTEXT_KEY = new InjectionToken<string>('CONTEXT_KEY ', {
  providedIn: 'root',
  factory: (): string => 'jwt',
});

export const SHOULD_CHECK_TENANT_ID = new InjectionToken<boolean>('SHOULD_CHECK_TENANT_ID ', {
  providedIn: 'root',
  factory: (): boolean => true,
});

function setHeaders(
  request: HttpRequest<any>,
  token: string,
  shouldCheckTenantId: boolean,
  tenantId: string
): HttpRequest<any> {
  if (token) {
    const headers: Record<string, string> = { Authorization: `Bearer ${token}` };

    if (shouldCheckTenantId) {
      headers[X_TENANT_ID] = tenantId;
    }
    request = request.clone({ setHeaders: headers });
  }

  return request;
}

export const bearerTokenInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
  cookies: CookieService = inject(CookieService),
  appDomainService: AppDomainService = inject(AppDomainService),
  contextKey: string = inject(CONTEXT_KEY),
  shouldCheckTenantId: boolean = inject(SHOULD_CHECK_TENANT_ID)
): Observable<HttpEvent<any>> => {
  const tokenKey: string = request.context.get(new HttpContextToken<string>(() => contextKey));
  const token: string | undefined = cookies.get(tokenKey);
  const clonedRequest: HttpRequest<any> = setHeaders(
    request,
    token || '',
    shouldCheckTenantId,
    appDomainService.tenantId
  );

  return next(clonedRequest);
};
