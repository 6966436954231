import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CalendarFacade } from '@fizjo-pro/data-calendar';
import { PatientDto, PatientFacade } from '@fizjo-pro/data-patient';
import { ProgressBarService } from '@fizjo-pro/shared/ui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { catchError, iif, map, NEVER, Observable, of, switchMap, take, tap } from 'rxjs';
import { from } from 'rxjs';

import {
  AppointmentDto,
  AppointmentGeneralDto,
  AppointmentGeneralPayloadDto,
  AppointmentsListItemDto,
  AppointmentVerificationStatus,
} from '../../api/models';
import { AppointmentService } from '../../api/services/appointment.service';
import { generalActions } from '../appointment.actions';

@Injectable()
export class GeneralEffects {
  #translateService: TranslateService = inject(TranslateService);
  #action$: Actions = inject(Actions);
  #appointmentService: AppointmentService = inject(AppointmentService);
  #messageService: MessageService = inject(MessageService);
  #router: Router = inject(Router);
  #patientFacade: PatientFacade = inject(PatientFacade);
  #calendarFacade: CalendarFacade = inject(CalendarFacade);
  #progressBarService: ProgressBarService = inject(ProgressBarService);

  public create$ = createEffect(() => {
    let eventId: string | undefined;

    return this.#action$.pipe(
      ofType(generalActions.createGeneral),
      tap(() => this.#progressBarService.show()),
      switchMap(action => this.#createAppointment$(action.payload, action.files, action.eventId)),
      switchMap((appointment: AppointmentGeneralDto) => this.#createAppointmentItem$(appointment)),
      take(1),
      tap(() => {
        if (eventId !== undefined) {
          this.#calendarFacade.deleteEvent(eventId);
        }
      }),
      map((appointment: AppointmentsListItemDto) => generalActions.createGeneralSuccess({ appointment }))
    );
  });

  public createSuccess$ = createEffect(
    () => {
      return this.#action$.pipe(
        ofType(generalActions.createGeneralSuccess),
        tap(action => {
          this.#messageService.add({
            detail: this.#translateService.instant('general.saveSuccess', {
              patientName: action.appointment.patientData.name,
            }),
            severity: 'success',
            summary: this.#translateService.instant('general.general'),
          });
          this.#progressBarService.hide();
        }),
        switchMap(action =>
          action.appointment.verificationStatus === AppointmentVerificationStatus.PENDING
            ? from(this.#router.navigate(['/appointments']))
            : from(this.#router.navigate(['/appointments', action.appointment._id]))
        ),
        catchError(() => {
          this.#progressBarService.hide();

          return NEVER;
        })
      );
    },
    { dispatch: false }
  );

  public update$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(generalActions.updateGeneral),
      switchMap(action =>
        this.#updateAppointment$(action.appointmentId, action.payload, action.files, action.filesToRemove)
      ),
      map((appointment: AppointmentDto) => generalActions.updateGeneralSuccess({ appointment }))
    );
  });
  public updateSuccess$ = createEffect(
    () => {
      return this.#action$.pipe(
        ofType(generalActions.updateGeneralSuccess),
        switchMap(() => this.#translateService.get(['general.updateSuccess', 'general.general'])),
        tap((translate: Record<string, string>) => {
          this.#messageService.add({
            detail: translate['general.updateSuccess'],
            severity: 'success',
            summary: translate['general.general'],
          });
          this.#router.navigate(['/appointments']);
        })
      );
    },
    { dispatch: false }
  );

  #createAppointmentItem$(appointmentDto: AppointmentGeneralDto): Observable<AppointmentsListItemDto> {
    return this.#patientFacade.selectPatient$(appointmentDto.patient).pipe(
      map((patient: PatientDto | undefined) => {
        return {
          ...(appointmentDto as AppointmentsListItemDto),
          patientData: patient as PatientDto,
        };
      })
    );
  }

  #uploadFilesForAppointment$(
    appointment: AppointmentGeneralDto,
    attachments: File[] = []
  ): Observable<AppointmentGeneralDto> {
    return iif(
      () => attachments.length === 0,
      of(appointment),
      this.#appointmentService.appointmentGeneralFilesControllerAppendFiles({
        body: { attachments },
        appointmentId: appointment._id,
      })
    );
  }

  #createAppointment$(
    payload: AppointmentGeneralPayloadDto,
    files?: File[],
    eventId?: string
  ): Observable<AppointmentGeneralDto> {
    return this.#appointmentService.appointmentGeneralControllerCreate({ eventId, body: payload }).pipe(
      tap(() => {
        this.#messageService.add({
          detail: this.#translateService.instant('general.savePartiallySuccess'),
          severity: 'success',
          summary: this.#translateService.instant('general.general'),
        });
      }),
      switchMap(appointment => this.#uploadFilesForAppointment$(appointment, files))
    );
  }

  #updateAppointment$(
    appointmentId: string,
    payload: AppointmentGeneralPayloadDto,
    attachments: File[] = [],
    filesToRemove: string[] = []
  ): Observable<AppointmentDto> {
    return this.#appointmentService.appointmentGeneralControllerUpdate({ appointmentId, body: payload }).pipe(
      tap(() => {
        this.#messageService.add({
          detail: this.#translateService.instant('general.updatePartiallySuccess'),
          severity: 'success',
          summary: this.#translateService.instant('general.general'),
        });
      }),
      switchMap(appointment =>
        this.#appointmentService.appointmentGeneralFilesControllerUpdateFiles({
          appointmentId: appointment._id,
          body: { attachments, filesToRemove },
        })
      )
    );
  }
}
