/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  PROCEDURE_TEMPLATE_FEATURE_KEY,
  ProcedureTemplateFilter,
  ProcedureTemplateState,
} from './procedure-template.model';
import { selectAll } from './procedure-template.reducer';
import { ProcedureTemplateDto } from '../api/models/procedure-template-dto';

export const selectTemplateState = createFeatureSelector<ProcedureTemplateState>(PROCEDURE_TEMPLATE_FEATURE_KEY);

export const selectReady = createSelector(selectTemplateState, (state: ProcedureTemplateState) => state.ready);

export const selectMany = (ids: string[]) =>
  createSelector(selectTemplateState, (state: ProcedureTemplateState) => {
    return ids
      .map(id => selectAll(state).find(template => template._id === id))
      .filter(Boolean) as ProcedureTemplateDto[];
  });
export const selectTemplates = createSelector(selectTemplateState, (state: ProcedureTemplateState) =>
  selectAll(state).filter((eq: ProcedureTemplateDto) => {
    switch (state.filter) {
      case ProcedureTemplateFilter.ACTIVE:
        return eq.active;
      case ProcedureTemplateFilter.INACTIVE:
        return !eq.active;
      default:
        return true;
    }
  })
);

export const selectActiveOnly = createSelector(selectTemplateState, (state: ProcedureTemplateState) =>
  selectAll(state).filter((eq: ProcedureTemplateDto) => eq.active)
);

export const selectById = (equipmentId: string) =>
  createSelector(selectTemplateState, (state: ProcedureTemplateState) => state.entities[equipmentId]);

export const selectFilterValue = createSelector(selectTemplateState, (state: ProcedureTemplateState) => state.filter);
