import { clearState } from '@fizjo-pro/data-auth';
import { cast } from '@kate-fizjo/shared/tools';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { appointmentActions, attachmentActions, generalActions, procedureActions } from './appointment.actions';
import { AppointmentFullDataDto, AppointmentState } from './appointment.models';
import { AppointmentDto, AppointmentsListItemDto, AttachmentDto } from '../api/models';

export const appointmentAdapter: EntityAdapter<AppointmentFullDataDto> = createEntityAdapter<AppointmentFullDataDto>({
  selectId: (appointment: AppointmentFullDataDto) => appointment._id,
});

export const initialAppointmentState: AppointmentState = {
  ...appointmentAdapter.getInitialState(),
  ready: false,
};

const reducer = createReducer(
  initialAppointmentState,

  /* General appointment actions */
  on(generalActions.createGeneralSuccess, (state: AppointmentState, action) => ({
    ...appointmentAdapter.upsertOne(action.appointment as AppointmentDto, state),
    ready: true,
  })),
  on(generalActions.updateGeneralSuccess, (state: AppointmentState, action) => ({
    ...state,
    ...appointmentAdapter.upsertOne(action.appointment, state),
  })),

  /* Common appointment actions */
  on(appointmentActions.fetchSingleAppointmentSuccess, (state, action) => {
    const listItem: AppointmentsListItemDto | undefined = state.entities[action.appointmentDto._id];

    return listItem
      ? appointmentAdapter.updateOne(
          {
            changes: { ...action.appointmentDto, ready: true },
            id: action.appointmentDto._id,
          },
          state
        )
      : appointmentAdapter.upsertOne(action.appointmentDto, state);
  }),
  on(appointmentActions.fetchAllForPatientSuccess, (state, action) => {
    const ids: string[] = state.ids.map(id => id.toString(10));
    const appointmentsFromAction: AppointmentsListItemDto[] = action.appointments.filter(
      (appointment: AppointmentsListItemDto) => !ids.includes(appointment._id)
    );

    return { ...appointmentAdapter.upsertMany(cast<AppointmentDto[]>(appointmentsFromAction), state) };
  }),
  on(appointmentActions.fetchSuccess, (state, action) => ({
    ...appointmentAdapter.upsertMany(cast<AppointmentDto[]>(action.appointments), state),
    ready: true,
  })),
  on(appointmentActions.deleteSuccess, (state: AppointmentState, action) =>
    appointmentAdapter.removeOne(action.appointmentId, state)
  ),
  /* Notes actions */
  on(appointmentActions.createNoteSuccess, (state: AppointmentState, action) =>
    appointmentAdapter.updateOne({ changes: action.appointmentDto, id: action.appointmentDto._id }, state)
  ),
  on(appointmentActions.updateNoteSuccess, (state: AppointmentState, action) =>
    appointmentAdapter.updateOne({ changes: action.appointmentDto, id: action.appointmentDto._id }, state)
  ),
  on(appointmentActions.deleteNoteSuccess, (state: AppointmentState, action) =>
    appointmentAdapter.updateOne({ changes: action.appointmentDto, id: action.appointmentDto._id }, state)
  ),

  /* Procedure actions */
  on(procedureActions.createProcedureSuccess, (state: AppointmentState, action) => ({
    ...state,
    ...appointmentAdapter.upsertOne(action.appointment, state),
  })),
  on(procedureActions.updateProcedureSuccess, (state: AppointmentState, action) =>
    appointmentAdapter.updateOne({ changes: action.appointment, id: action.appointment._id }, state)
  ),

  /* Attachment actions */
  on(attachmentActions.deleteAttachments, (state: AppointmentState, action) => {
    const appointment: AppointmentDto | undefined = state.entities[action.appointmentId];
    const attachments: AttachmentDto[] | undefined = appointment?.attachments.filter(a =>
      action.attachmentId.includes(a._id.toString())
    );

    return appointmentAdapter.updateOne(
      {
        changes: { ...appointment, attachments },
        id: action.appointmentId,
      },
      state
    );
  }),
  on(clearState, () => initialAppointmentState)
);

export function appointmentReducer(state: AppointmentState | undefined, action: Action): AppointmentState {
  return reducer(state, action);
}
