/* tslint:disable */
/* eslint-disable */
export enum AppointmentLogType {
  CREATE = 'CREATE',
  MODIFY = 'MODIFY',
  CREATE_NOTE = 'CREATE_NOTE',
  UPDATE_NOTE = 'UPDATE_NOTE',
  DELETE_NOTE = 'DELETE_NOTE',
  DELETE_ATTACHMENT = 'DELETE_ATTACHMENT',
}
