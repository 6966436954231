import { Action, createReducer, on } from '@ngrx/store';

import { gdprActions } from './gdpr.actions';
import { GdprSettingsState } from './gdpr.model';

export const initialState: GdprSettingsState = {
  gdprSettings: null,
  ready: false,
};
export const gdprReducers = createReducer(
  initialState,
  on(gdprActions.readSuccess, (state, action) => ({
    ...state,
    gdprSettings: action.gdprSettings,
    ready: true,
  })),
  on(gdprActions.updateSuccess, (state, action) => ({
    ...state,
    gdprSettings: action.gdprSettings,
    ready: true,
  }))
);

export function billingDataReducer(state: GdprSettingsState | undefined, action: Action): GdprSettingsState {
  return gdprReducers(state, action);
}
