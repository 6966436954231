/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MessageChannel } from '../models/message-channel';
import { MessageContentDto } from '../models/message-content-dto';
import { OwnerDto } from '../models/owner-dto';
import { OwnerPayloadDto } from '../models/owner-payload-dto';
import { OwnerUpdateDto } from '../models/owner-update-dto';

@Injectable({
  providedIn: 'root',
})
export class OwnerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation ownerControllerReadAll
   */
  static readonly OwnerControllerReadAllPath = '/api/owner';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownerControllerReadAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownerControllerReadAll$Response(params: {
    'x-tenant-id': string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<Array<OwnerDto>>> {
    const rb = new RequestBuilder(this.rootUrl, OwnerService.OwnerControllerReadAllPath, 'get');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<OwnerDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ownerControllerReadAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownerControllerReadAll(params: { 'x-tenant-id': string; context?: HttpContext }): Observable<Array<OwnerDto>> {
    return this.ownerControllerReadAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OwnerDto>>) => r.body as Array<OwnerDto>)
    );
  }

  /**
   * Path part for operation ownerControllerCreate
   */
  static readonly OwnerControllerCreatePath = '/api/owner';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownerControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownerControllerCreate$Response(params: {
    'x-tenant-id': string;
    context?: HttpContext;
    body: OwnerPayloadDto;
  }): Observable<StrictHttpResponse<OwnerDto>> {
    const rb = new RequestBuilder(this.rootUrl, OwnerService.OwnerControllerCreatePath, 'post');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OwnerDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ownerControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownerControllerCreate(params: {
    'x-tenant-id': string;
    context?: HttpContext;
    body: OwnerPayloadDto;
  }): Observable<OwnerDto> {
    return this.ownerControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<OwnerDto>) => r.body as OwnerDto)
    );
  }

  /**
   * Path part for operation ownerControllerReadOne
   */
  static readonly OwnerControllerReadOnePath = '/api/owner/{ownerId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownerControllerReadOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownerControllerReadOne$Response(params: {
    /**
     * The animal owner MongoDb identifier
     */
    ownerId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<OwnerDto>> {
    const rb = new RequestBuilder(this.rootUrl, OwnerService.OwnerControllerReadOnePath, 'get');
    if (params) {
      rb.path('ownerId', params.ownerId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OwnerDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ownerControllerReadOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownerControllerReadOne(params: {
    /**
     * The animal owner MongoDb identifier
     */
    ownerId: string;
    context?: HttpContext;
  }): Observable<OwnerDto> {
    return this.ownerControllerReadOne$Response(params).pipe(
      map((r: StrictHttpResponse<OwnerDto>) => r.body as OwnerDto)
    );
  }

  /**
   * Path part for operation ownerControllerUpdate
   */
  static readonly OwnerControllerUpdatePath = '/api/owner/{ownerId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownerControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownerControllerUpdate$Response(params: {
    /**
     * The animal owner MongoDb identifier
     */
    ownerId: string;
    context?: HttpContext;
    body: OwnerUpdateDto;
  }): Observable<StrictHttpResponse<OwnerDto>> {
    const rb = new RequestBuilder(this.rootUrl, OwnerService.OwnerControllerUpdatePath, 'patch');
    if (params) {
      rb.path('ownerId', params.ownerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OwnerDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ownerControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownerControllerUpdate(params: {
    /**
     * The animal owner MongoDb identifier
     */
    ownerId: string;
    context?: HttpContext;
    body: OwnerUpdateDto;
  }): Observable<OwnerDto> {
    return this.ownerControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<OwnerDto>) => r.body as OwnerDto)
    );
  }

  /**
   * Path part for operation ownerControllerCreateInvitation
   */
  static readonly OwnerControllerCreateInvitationPath = '/api/owner/{ownerId}/invitation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownerControllerCreateInvitation()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownerControllerCreateInvitation$Response(params: {
    /**
     * The animal owner MongoDb identifier
     */
    ownerId: string;
    'x-tenant-id': string;

    /**
     * How the message should be sent
     */
    channel: MessageChannel;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, OwnerService.OwnerControllerCreateInvitationPath, 'get');
    if (params) {
      rb.path('ownerId', params.ownerId, {});
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.query('channel', params.channel, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ownerControllerCreateInvitation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownerControllerCreateInvitation(params: {
    /**
     * The animal owner MongoDb identifier
     */
    ownerId: string;
    'x-tenant-id': string;

    /**
     * How the message should be sent
     */
    channel: MessageChannel;
    context?: HttpContext;
  }): Observable<void> {
    return this.ownerControllerCreateInvitation$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation ownerControllerRequestGdpr
   */
  static readonly OwnerControllerRequestGdprPath = '/api/owner/{ownerId}/gdpr';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownerControllerRequestGdpr()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownerControllerRequestGdpr$Response(params: {
    'x-tenant-id': string;

    /**
     * The animal owner MongoDb identifier
     */
    ownerId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, OwnerService.OwnerControllerRequestGdprPath, 'post');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.path('ownerId', params.ownerId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ownerControllerRequestGdpr$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownerControllerRequestGdpr(params: {
    'x-tenant-id': string;

    /**
     * The animal owner MongoDb identifier
     */
    ownerId: string;
    context?: HttpContext;
  }): Observable<void> {
    return this.ownerControllerRequestGdpr$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation ownerControllerAcceptGdpr
   */
  static readonly OwnerControllerAcceptGdprPath = '/api/owner/{ownerId}/gdpr';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownerControllerAcceptGdpr()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownerControllerAcceptGdpr$Response(params: {
    'x-tenant-id': string;

    /**
     * The animal owner MongoDb identifier
     */
    ownerId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, OwnerService.OwnerControllerAcceptGdprPath, 'patch');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.path('ownerId', params.ownerId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ownerControllerAcceptGdpr$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownerControllerAcceptGdpr(params: {
    'x-tenant-id': string;

    /**
     * The animal owner MongoDb identifier
     */
    ownerId: string;
    context?: HttpContext;
  }): Observable<void> {
    return this.ownerControllerAcceptGdpr$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation ownerControllerSendMessage
   */
  static readonly OwnerControllerSendMessagePath = '/api/owner/{ownerId}/message';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownerControllerSendMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownerControllerSendMessage$Response(params: {
    /**
     * How the message should be sent
     */
    channel: MessageChannel;
    'x-tenant-id': string;

    /**
     * The animal owner MongoDb identifier
     */
    ownerId: string;
    context?: HttpContext;
    body: MessageContentDto;
  }): Observable<StrictHttpResponse<{}>> {
    const rb = new RequestBuilder(this.rootUrl, OwnerService.OwnerControllerSendMessagePath, 'post');
    if (params) {
      rb.query('channel', params.channel, {});
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.path('ownerId', params.ownerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{}>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ownerControllerSendMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownerControllerSendMessage(params: {
    /**
     * How the message should be sent
     */
    channel: MessageChannel;
    'x-tenant-id': string;

    /**
     * The animal owner MongoDb identifier
     */
    ownerId: string;
    context?: HttpContext;
    body: MessageContentDto;
  }): Observable<{}> {
    return this.ownerControllerSendMessage$Response(params).pipe(map((r: StrictHttpResponse<{}>) => r.body as {}));
  }

  /**
   * Path part for operation ownerControllerDeleteCustomerAppToken
   */
  static readonly OwnerControllerDeleteCustomerAppTokenPath = '/api/owner/{ownerId}/customer-app-token';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownerControllerDeleteCustomerAppToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownerControllerDeleteCustomerAppToken$Response(params: {
    /**
     * The animal owner MongoDb identifier
     */
    ownerId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<OwnerDto>> {
    const rb = new RequestBuilder(this.rootUrl, OwnerService.OwnerControllerDeleteCustomerAppTokenPath, 'delete');
    if (params) {
      rb.path('ownerId', params.ownerId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OwnerDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ownerControllerDeleteCustomerAppToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownerControllerDeleteCustomerAppToken(params: {
    /**
     * The animal owner MongoDb identifier
     */
    ownerId: string;
    context?: HttpContext;
  }): Observable<OwnerDto> {
    return this.ownerControllerDeleteCustomerAppToken$Response(params).pipe(
      map((r: StrictHttpResponse<OwnerDto>) => r.body as OwnerDto)
    );
  }
}
