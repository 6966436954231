import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

import { AccountActions } from './account.actions';
import { AccountDto } from '../api/models/account-dto';
import { AccountService } from '../api/services/account.service';

@Injectable()
export class AccountEffects {
  #actions$ = inject(Actions);
  #accountService = inject(AccountService);

  readAccount$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(AccountActions.load),
      switchMap(() =>
        this.#accountService
          .accountControllerReadOne({})
          .pipe(map((account: AccountDto) => AccountActions.loadSuccess({ account })))
      )
    );
  });
}
