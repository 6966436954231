/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FormTemplateDto } from '../models/form-template-dto';
import { FormTemplateFieldParamsDto } from '../models/form-template-field-params-dto';
import { FormTemplatePayloadDto } from '../models/form-template-payload-dto';
import { FormTemplateSelectedForDto } from '../models/form-template-selected-for-dto';
import { FormTemplateStatsDto } from '../models/form-template-stats-dto';
import { FormTemplateUpdateActiveDto } from '../models/form-template-update-active-dto';

@Injectable({
  providedIn: 'root',
})
export class FormTemplateService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation formTemplateControllerReadAll
   */
  static readonly FormTemplateControllerReadAllPath = '/api/form-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formTemplateControllerReadAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  formTemplateControllerReadAll$Response(params: {
    'x-tenant-id': string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<Array<FormTemplateDto>>> {
    const rb = new RequestBuilder(this.rootUrl, FormTemplateService.FormTemplateControllerReadAllPath, 'get');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<FormTemplateDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `formTemplateControllerReadAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  formTemplateControllerReadAll(params: {
    'x-tenant-id': string;
    context?: HttpContext;
  }): Observable<Array<FormTemplateDto>> {
    return this.formTemplateControllerReadAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FormTemplateDto>>) => r.body as Array<FormTemplateDto>)
    );
  }

  /**
   * Path part for operation formTemplateControllerCreate
   */
  static readonly FormTemplateControllerCreatePath = '/api/form-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formTemplateControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  formTemplateControllerCreate$Response(params: {
    'x-tenant-id': string;
    context?: HttpContext;
    body: FormTemplatePayloadDto;
  }): Observable<StrictHttpResponse<FormTemplateDto>> {
    const rb = new RequestBuilder(this.rootUrl, FormTemplateService.FormTemplateControllerCreatePath, 'post');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<FormTemplateDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `formTemplateControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  formTemplateControllerCreate(params: {
    'x-tenant-id': string;
    context?: HttpContext;
    body: FormTemplatePayloadDto;
  }): Observable<FormTemplateDto> {
    return this.formTemplateControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<FormTemplateDto>) => r.body as FormTemplateDto)
    );
  }

  /**
   * Path part for operation formTemplateControllerStats
   */
  static readonly FormTemplateControllerStatsPath = '/api/form-template/stats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formTemplateControllerStats()` instead.
   *
   * This method doesn't expect any request body.
   */
  formTemplateControllerStats$Response(params: {
    'x-tenant-id': string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<Array<FormTemplateStatsDto>>> {
    const rb = new RequestBuilder(this.rootUrl, FormTemplateService.FormTemplateControllerStatsPath, 'get');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<FormTemplateStatsDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `formTemplateControllerStats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  formTemplateControllerStats(params: {
    'x-tenant-id': string;
    context?: HttpContext;
  }): Observable<Array<FormTemplateStatsDto>> {
    return this.formTemplateControllerStats$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FormTemplateStatsDto>>) => r.body as Array<FormTemplateStatsDto>)
    );
  }

  /**
   * Path part for operation formTemplateControllerReadParams
   */
  static readonly FormTemplateControllerReadParamsPath = '/api/form-template/field-params';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formTemplateControllerReadParams()` instead.
   *
   * This method doesn't expect any request body.
   */
  formTemplateControllerReadParams$Response(params?: {
    context?: HttpContext;
  }): Observable<StrictHttpResponse<FormTemplateFieldParamsDto>> {
    const rb = new RequestBuilder(this.rootUrl, FormTemplateService.FormTemplateControllerReadParamsPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<FormTemplateFieldParamsDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `formTemplateControllerReadParams$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  formTemplateControllerReadParams(params?: { context?: HttpContext }): Observable<FormTemplateFieldParamsDto> {
    return this.formTemplateControllerReadParams$Response(params).pipe(
      map((r: StrictHttpResponse<FormTemplateFieldParamsDto>) => r.body as FormTemplateFieldParamsDto)
    );
  }

  /**
   * Path part for operation formTemplateControllerReadOne
   */
  static readonly FormTemplateControllerReadOnePath = '/api/form-template/{templateId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formTemplateControllerReadOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  formTemplateControllerReadOne$Response(params: {
    /**
     * The MongoDB identifier of form template
     */
    templateId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<FormTemplateDto>> {
    const rb = new RequestBuilder(this.rootUrl, FormTemplateService.FormTemplateControllerReadOnePath, 'get');
    if (params) {
      rb.path('templateId', params.templateId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<FormTemplateDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `formTemplateControllerReadOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  formTemplateControllerReadOne(params: {
    /**
     * The MongoDB identifier of form template
     */
    templateId: string;
    context?: HttpContext;
  }): Observable<FormTemplateDto> {
    return this.formTemplateControllerReadOne$Response(params).pipe(
      map((r: StrictHttpResponse<FormTemplateDto>) => r.body as FormTemplateDto)
    );
  }

  /**
   * Path part for operation formTemplateControllerUpdate
   */
  static readonly FormTemplateControllerUpdatePath = '/api/form-template/{templateId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formTemplateControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  formTemplateControllerUpdate$Response(params: {
    /**
     * The MongoDB identifier of form template
     */
    templateId: string;
    context?: HttpContext;
    body: FormTemplatePayloadDto;
  }): Observable<StrictHttpResponse<FormTemplateDto>> {
    const rb = new RequestBuilder(this.rootUrl, FormTemplateService.FormTemplateControllerUpdatePath, 'put');
    if (params) {
      rb.path('templateId', params.templateId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<FormTemplateDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `formTemplateControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  formTemplateControllerUpdate(params: {
    /**
     * The MongoDB identifier of form template
     */
    templateId: string;
    context?: HttpContext;
    body: FormTemplatePayloadDto;
  }): Observable<FormTemplateDto> {
    return this.formTemplateControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<FormTemplateDto>) => r.body as FormTemplateDto)
    );
  }

  /**
   * Path part for operation formTemplateControllerDelete
   */
  static readonly FormTemplateControllerDeletePath = '/api/form-template/{templateId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formTemplateControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  formTemplateControllerDelete$Response(params: {
    'x-tenant-id': string;

    /**
     * The MongoDB identifier of form template
     */
    templateId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, FormTemplateService.FormTemplateControllerDeletePath, 'delete');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.path('templateId', params.templateId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `formTemplateControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  formTemplateControllerDelete(params: {
    'x-tenant-id': string;

    /**
     * The MongoDB identifier of form template
     */
    templateId: string;
    context?: HttpContext;
  }): Observable<void> {
    return this.formTemplateControllerDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation formTemplateControllerCount
   */
  static readonly FormTemplateControllerCountPath = '/api/form-template/{templateId}/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formTemplateControllerCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  formTemplateControllerCount$Response(params: {
    /**
     * The MongoDB identifier of form template
     */
    templateId: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<Array<FormTemplateStatsDto>>> {
    const rb = new RequestBuilder(this.rootUrl, FormTemplateService.FormTemplateControllerCountPath, 'get');
    if (params) {
      rb.path('templateId', params.templateId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<FormTemplateStatsDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `formTemplateControllerCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  formTemplateControllerCount(params: {
    /**
     * The MongoDB identifier of form template
     */
    templateId: string;
    context?: HttpContext;
  }): Observable<Array<FormTemplateStatsDto>> {
    return this.formTemplateControllerCount$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FormTemplateStatsDto>>) => r.body as Array<FormTemplateStatsDto>)
    );
  }

  /**
   * Path part for operation formTemplateControllerSetActive
   */
  static readonly FormTemplateControllerSetActivePath = '/api/form-template/{templateId}/active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formTemplateControllerSetActive()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  formTemplateControllerSetActive$Response(params: {
    /**
     * The MongoDB identifier of form template
     */
    templateId: string;
    context?: HttpContext;
    body: FormTemplateUpdateActiveDto;
  }): Observable<StrictHttpResponse<FormTemplateDto>> {
    const rb = new RequestBuilder(this.rootUrl, FormTemplateService.FormTemplateControllerSetActivePath, 'patch');
    if (params) {
      rb.path('templateId', params.templateId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<FormTemplateDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `formTemplateControllerSetActive$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  formTemplateControllerSetActive(params: {
    /**
     * The MongoDB identifier of form template
     */
    templateId: string;
    context?: HttpContext;
    body: FormTemplateUpdateActiveDto;
  }): Observable<FormTemplateDto> {
    return this.formTemplateControllerSetActive$Response(params).pipe(
      map((r: StrictHttpResponse<FormTemplateDto>) => r.body as FormTemplateDto)
    );
  }

  /**
   * Path part for operation formTemplateControllerSetSelectedFor
   */
  static readonly FormTemplateControllerSetSelectedForPath = '/api/form-template/{templateId}/selectedFor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formTemplateControllerSetSelectedFor()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  formTemplateControllerSetSelectedFor$Response(params: {
    /**
     * The MongoDB identifier of form template
     */
    templateId: string;
    'x-tenant-id': string;
    context?: HttpContext;
    body: FormTemplateSelectedForDto;
  }): Observable<StrictHttpResponse<Array<FormTemplateDto>>> {
    const rb = new RequestBuilder(this.rootUrl, FormTemplateService.FormTemplateControllerSetSelectedForPath, 'patch');
    if (params) {
      rb.path('templateId', params.templateId, {});
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<FormTemplateDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `formTemplateControllerSetSelectedFor$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  formTemplateControllerSetSelectedFor(params: {
    /**
     * The MongoDB identifier of form template
     */
    templateId: string;
    'x-tenant-id': string;
    context?: HttpContext;
    body: FormTemplateSelectedForDto;
  }): Observable<Array<FormTemplateDto>> {
    return this.formTemplateControllerSetSelectedFor$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FormTemplateDto>>) => r.body as Array<FormTemplateDto>)
    );
  }
}
