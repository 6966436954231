import { Injectable } from '@angular/core';
import { MeService } from '@fizjo-pro/util-auth';
import { Store } from '@ngrx/store';
import { Observable, take, tap } from 'rxjs';

import { UserDto } from './api/models/user-dto';
import { UserPayloadDto } from './api/models/user-payload-dto';
import { appUsersActions } from './state/app-users.actions';
import { selectAllUsers, selectReady, selectUser } from './state/app-users.selectors';

@Injectable({ providedIn: 'root' })
export class AppUsersFacade {
  public users$!: Observable<UserDto[]>;
  public ready$!: Observable<boolean>;

  constructor(
    private store: Store,
    private meService: MeService
  ) {
    this.users$ = store.select(selectAllUsers);
    this.ready$ = store.select(selectReady);
  }

  public create(user: UserPayloadDto): void {
    this.store.dispatch(appUsersActions.create({ user }));
  }

  public fetch(): void {
    this.store.dispatch(appUsersActions.fetch());
  }

  public update(uid: string, payload: UserPayloadDto): void {
    this.store.dispatch(appUsersActions.update({ payload, uid }));
  }

  public user$(uid: string | null): Observable<UserDto | undefined> {
    return this.store.select(selectUser(uid));
  }

  public changeOwnPassword(password: string): void {
    this.meService.me$.pipe(take(1)).subscribe((me: UserDto | null) => {
      if (me) {
        this.store.dispatch(appUsersActions.patchPassword({ password, uid: me._id, email: me.email }));
      }
    });
  }

  public patchPinCode(pinCode: string, mfaCode: string): void {
    this.meService.me$
      .pipe(
        take(1),
        tap((me: UserDto | null) => {
          if (me) {
            this.store.dispatch(
              appUsersActions.patchPinCode({
                payload: {
                  mfaCode,
                  phoneNumber: me.phoneNumber,
                  pinCode,
                },
              })
            );
          }
        })
      )
      .subscribe();
  }
}
