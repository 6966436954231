/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ChangePasswordDto } from '../models/change-password-dto';
import { ChangePasswordPayloadDto } from '../models/change-password-payload-dto';
import { MyDataPayloadDto } from '../models/my-data-payload-dto';
import { PatchActiveDto } from '../models/patch-active-dto';
import { PatchRoleDto } from '../models/patch-role-dto';
import { PinCodePayloadDto } from '../models/pin-code-payload-dto';
import { UserDto } from '../models/user-dto';
import { UserPayloadDto } from '../models/user-payload-dto';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation authenticatedUserControllerReadMe
   */
  static readonly AuthenticatedUserControllerReadMePath = '/api/user/me';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticatedUserControllerReadMe()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticatedUserControllerReadMe$Response(params?: {
    context?: HttpContext;
  }): Observable<StrictHttpResponse<UserDto>> {
    const rb = new RequestBuilder(this.rootUrl, UserService.AuthenticatedUserControllerReadMePath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authenticatedUserControllerReadMe$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticatedUserControllerReadMe(params?: { context?: HttpContext }): Observable<UserDto> {
    return this.authenticatedUserControllerReadMe$Response(params).pipe(
      map((r: StrictHttpResponse<UserDto>) => r.body as UserDto)
    );
  }

  /**
   * Path part for operation ownUserControllerUpdateMe
   */
  static readonly OwnUserControllerUpdateMePath = '/api/user/me';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownUserControllerUpdateMe()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownUserControllerUpdateMe$Response(params: {
    context?: HttpContext;
    body: MyDataPayloadDto;
  }): Observable<StrictHttpResponse<UserDto>> {
    const rb = new RequestBuilder(this.rootUrl, UserService.OwnUserControllerUpdateMePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ownUserControllerUpdateMe$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownUserControllerUpdateMe(params: { context?: HttpContext; body: MyDataPayloadDto }): Observable<UserDto> {
    return this.ownUserControllerUpdateMe$Response(params).pipe(
      map((r: StrictHttpResponse<UserDto>) => r.body as UserDto)
    );
  }

  /**
   * Path part for operation ownUserControllerUpdatePinCode
   */
  static readonly OwnUserControllerUpdatePinCodePath = '/api/user/me/pin-code';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownUserControllerUpdatePinCode()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownUserControllerUpdatePinCode$Response(params: {
    context?: HttpContext;
    body: PinCodePayloadDto;
  }): Observable<StrictHttpResponse<UserDto>> {
    const rb = new RequestBuilder(this.rootUrl, UserService.OwnUserControllerUpdatePinCodePath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ownUserControllerUpdatePinCode$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownUserControllerUpdatePinCode(params: { context?: HttpContext; body: PinCodePayloadDto }): Observable<UserDto> {
    return this.ownUserControllerUpdatePinCode$Response(params).pipe(
      map((r: StrictHttpResponse<UserDto>) => r.body as UserDto)
    );
  }

  /**
   * Path part for operation userControllerCheckEmail
   */
  static readonly UserControllerCheckEmailPath = '/api/user/email';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerCheckEmail()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerCheckEmail$Response(params: {
    /**
     * User email address
     */
    email: string;
    'x-tenant-id': string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, UserService.UserControllerCheckEmailPath, 'get');
    if (params) {
      rb.query('email', params.email, {});
      rb.header('x-tenant-id', params['x-tenant-id'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: String((r as HttpResponse<any>).body) === 'true',
          }) as StrictHttpResponse<boolean>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userControllerCheckEmail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerCheckEmail(params: {
    /**
     * User email address
     */
    email: string;
    'x-tenant-id': string;
    context?: HttpContext;
  }): Observable<boolean> {
    return this.userControllerCheckEmail$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation userControllerReadOne
   */
  static readonly UserControllerReadOnePath = '/api/user/{uid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerReadOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerReadOne$Response(params: {
    /**
     * User identifier
     */
    uid: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<UserDto>> {
    const rb = new RequestBuilder(this.rootUrl, UserService.UserControllerReadOnePath, 'get');
    if (params) {
      rb.path('uid', params.uid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userControllerReadOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerReadOne(params: {
    /**
     * User identifier
     */
    uid: string;
    context?: HttpContext;
  }): Observable<UserDto> {
    return this.userControllerReadOne$Response(params).pipe(map((r: StrictHttpResponse<UserDto>) => r.body as UserDto));
  }

  /**
   * Path part for operation authenticatedUserControllerUpdate
   */
  static readonly AuthenticatedUserControllerUpdatePath = '/api/user/{uid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticatedUserControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticatedUserControllerUpdate$Response(params: {
    /**
     * User identifier
     */
    uid: string;
    context?: HttpContext;
    body: UserPayloadDto;
  }): Observable<StrictHttpResponse<UserDto>> {
    const rb = new RequestBuilder(this.rootUrl, UserService.AuthenticatedUserControllerUpdatePath, 'put');
    if (params) {
      rb.path('uid', params.uid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authenticatedUserControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticatedUserControllerUpdate(params: {
    /**
     * User identifier
     */
    uid: string;
    context?: HttpContext;
    body: UserPayloadDto;
  }): Observable<UserDto> {
    return this.authenticatedUserControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<UserDto>) => r.body as UserDto)
    );
  }

  /**
   * Path part for operation userControllerPatchPassword
   */
  static readonly UserControllerPatchPasswordPath = '/api/user/{uid}/password';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerPatchPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerPatchPassword$Response(params: {
    /**
     * User identifier
     */
    uid: string;
    context?: HttpContext;
    body: ChangePasswordDto;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, UserService.UserControllerPatchPasswordPath, 'patch');
    if (params) {
      rb.path('uid', params.uid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userControllerPatchPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerPatchPassword(params: {
    /**
     * User identifier
     */
    uid: string;
    context?: HttpContext;
    body: ChangePasswordDto;
  }): Observable<void> {
    return this.userControllerPatchPassword$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation authenticatedUserControllerReadAll
   */
  static readonly AuthenticatedUserControllerReadAllPath = '/api/user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticatedUserControllerReadAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticatedUserControllerReadAll$Response(params: {
    'x-tenant-id': string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<Array<UserDto>>> {
    const rb = new RequestBuilder(this.rootUrl, UserService.AuthenticatedUserControllerReadAllPath, 'get');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<UserDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authenticatedUserControllerReadAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticatedUserControllerReadAll(params: {
    'x-tenant-id': string;
    context?: HttpContext;
  }): Observable<Array<UserDto>> {
    return this.authenticatedUserControllerReadAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserDto>>) => r.body as Array<UserDto>)
    );
  }

  /**
   * Path part for operation authenticatedUserControllerCreate
   */
  static readonly AuthenticatedUserControllerCreatePath = '/api/user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticatedUserControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticatedUserControllerCreate$Response(params: {
    'x-tenant-id': string;
    context?: HttpContext;
    body: UserPayloadDto;
  }): Observable<StrictHttpResponse<UserDto>> {
    const rb = new RequestBuilder(this.rootUrl, UserService.AuthenticatedUserControllerCreatePath, 'post');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authenticatedUserControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticatedUserControllerCreate(params: {
    'x-tenant-id': string;
    context?: HttpContext;
    body: UserPayloadDto;
  }): Observable<UserDto> {
    return this.authenticatedUserControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<UserDto>) => r.body as UserDto)
    );
  }

  /**
   * Path part for operation authenticatedUserControllerPatchRoles
   */
  static readonly AuthenticatedUserControllerPatchRolesPath = '/api/user/{uid}/role';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticatedUserControllerPatchRoles()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticatedUserControllerPatchRoles$Response(params: {
    /**
     * User identifier
     */
    uid: string;
    context?: HttpContext;
    body: PatchRoleDto;
  }): Observable<StrictHttpResponse<UserDto>> {
    const rb = new RequestBuilder(this.rootUrl, UserService.AuthenticatedUserControllerPatchRolesPath, 'patch');
    if (params) {
      rb.path('uid', params.uid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authenticatedUserControllerPatchRoles$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticatedUserControllerPatchRoles(params: {
    /**
     * User identifier
     */
    uid: string;
    context?: HttpContext;
    body: PatchRoleDto;
  }): Observable<UserDto> {
    return this.authenticatedUserControllerPatchRoles$Response(params).pipe(
      map((r: StrictHttpResponse<UserDto>) => r.body as UserDto)
    );
  }

  /**
   * Path part for operation authenticatedUserControllerPatchActive
   */
  static readonly AuthenticatedUserControllerPatchActivePath = '/api/user/{uid}/active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticatedUserControllerPatchActive()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticatedUserControllerPatchActive$Response(params: {
    /**
     * User identifier
     */
    uid: string;
    context?: HttpContext;
    body: PatchActiveDto;
  }): Observable<StrictHttpResponse<UserDto>> {
    const rb = new RequestBuilder(this.rootUrl, UserService.AuthenticatedUserControllerPatchActivePath, 'patch');
    if (params) {
      rb.path('uid', params.uid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authenticatedUserControllerPatchActive$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticatedUserControllerPatchActive(params: {
    /**
     * User identifier
     */
    uid: string;
    context?: HttpContext;
    body: PatchActiveDto;
  }): Observable<UserDto> {
    return this.authenticatedUserControllerPatchActive$Response(params).pipe(
      map((r: StrictHttpResponse<UserDto>) => r.body as UserDto)
    );
  }

  /**
   * Path part for operation passwordControllerRequestMfaCode
   */
  static readonly PasswordControllerRequestMfaCodePath = '/api/password/mfa';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `passwordControllerRequestMfaCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  passwordControllerRequestMfaCode$Response(params: {
    /**
     * User email address
     */
    email: string;
    'x-tenant-id': string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<{}>> {
    const rb = new RequestBuilder(this.rootUrl, UserService.PasswordControllerRequestMfaCodePath, 'get');
    if (params) {
      rb.query('email', params.email, {});
      rb.header('x-tenant-id', params['x-tenant-id'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{}>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `passwordControllerRequestMfaCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  passwordControllerRequestMfaCode(params: {
    /**
     * User email address
     */
    email: string;
    'x-tenant-id': string;
    context?: HttpContext;
  }): Observable<{}> {
    return this.passwordControllerRequestMfaCode$Response(params).pipe(
      map((r: StrictHttpResponse<{}>) => r.body as {})
    );
  }

  /**
   * Path part for operation passwordControllerPatchPassword
   */
  static readonly PasswordControllerPatchPasswordPath = '/api/password';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `passwordControllerPatchPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  passwordControllerPatchPassword$Response(params: {
    context?: HttpContext;
    body: ChangePasswordPayloadDto;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, UserService.PasswordControllerPatchPasswordPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `passwordControllerPatchPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  passwordControllerPatchPassword(params: { context?: HttpContext; body: ChangePasswordPayloadDto }): Observable<void> {
    return this.passwordControllerPatchPassword$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }
}
