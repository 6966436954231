import { Routes } from '@angular/router';
import { UserDataResolver } from '@fizjo-pro/data-auth';
import { AppUsersResolver } from '@fizjo-pro/settings/data-user';

export const userRoutes: Routes = [
  {
    loadComponent: () => import('./change-password/change-password.component').then(m => m.ChangePasswordComponent),
    path: 'change-password',
  },
  {
    loadComponent: () => import('./user-data/user-data.component').then(m => m.UserDataComponent),
    path: 'user-data',
    providers: [UserDataResolver],
    resolve: {
      user: UserDataResolver,
      users: AppUsersResolver,
    },
  },
  {
    loadComponent: () => import('./integration/integration.component').then(m => m.IntegrationComponent),
    path: 'integrations',
    providers: [UserDataResolver],
    resolve: {
      user: UserDataResolver,
    },
  },
  {
    loadComponent: () => import('./set-pin-code/set-pin-code.component').then(m => m.SetPinCodeComponent),
    path: 'pin-code',
    providers: [UserDataResolver],
    resolve: {
      user: UserDataResolver,
    },
  },
];
