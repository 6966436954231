import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppDomainService, X_TENANT_ID } from '@fizjo-pro/shared/util-app-domain';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { from, Observable, switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppointmentDto, AppointmentVerificationStatus } from '../../api/models';
import { AppointmentService } from '../../api/services/appointment.service';
import { appointmentActions, procedureActions } from '../appointment.actions';

@Injectable()
export class ProcedureEffects {
  #translateService: TranslateService = inject(TranslateService);
  #action$: Actions = inject(Actions);
  #appointmentService: AppointmentService = inject(AppointmentService);
  #messageService: MessageService = inject(MessageService);
  #router: Router = inject(Router);
  #appDomainService: AppDomainService = inject(AppDomainService);

  public create$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(procedureActions.createProcedure),
      switchMap(action =>
        this.#appointmentService.procedureControllerCreate({
          body: action.payload,
        })
      ),
      map((appointment: AppointmentDto) => procedureActions.createProcedureSuccess({ appointment }))
    );
  });
  public createProcedureSuccess$ = createEffect(
    () => {
      return this.#action$.pipe(
        ofType(procedureActions.createProcedureSuccess),
        switchMap(action => this.translateMessage$('createSuccess', action.appointment)),
        tap((data: { translate: Record<string, string>; passData: AppointmentDto }) => {
          this.#messageService.add({
            detail: data.translate['appointment.createSuccess.detail'],
            severity: 'success',
            summary: data.translate['appointment.createSuccess.summary'],
          });
          if (data.passData.verificationStatus === AppointmentVerificationStatus.PENDING) {
            return from(this.#router.navigate(['/appointments']));
          }

          return from(this.#router.navigate(['/appointments', data.passData._id]));
        })
      );
    },
    { dispatch: false }
  );
  public update$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(procedureActions.updateProcedure),
      switchMap(action =>
        this.#appointmentService.procedureControllerUpdate({
          appointmentId: action.appointmentId,
          body: action.payload,
        })
      ),
      map((appointment: AppointmentDto) => procedureActions.updateProcedureSuccess({ appointment }))
    );
  });
  public updateSuccess$ = createEffect(
    () => {
      return this.#action$.pipe(
        ofType(procedureActions.updateProcedureSuccess),
        switchMap(action => this.translateMessage$('updateSuccess', action.appointment)),
        tap((data: { translate: Record<string, string>; passData: AppointmentDto }) => {
          this.#messageService.add({
            detail: data.translate['appointment.updateSuccess.detail'],
            severity: 'success',
            summary: data.translate['appointment.updateSuccess.summary'],
          });
          if (data.passData.verificationStatus === AppointmentVerificationStatus.PENDING) {
            return from(this.#router.navigate(['/appointments']));
          }

          return from(this.#router.navigate(['/appointments', data.passData._id]));
        })
      );
    },
    { dispatch: false }
  );
  public createNote$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(appointmentActions.createNote),
      switchMap(action =>
        this.#appointmentService.appointmentsControllerPushNote({
          appointmentId: action.appointmentId,
          body: { note: action.note },
          [X_TENANT_ID]: this.#appDomainService.tenantId,
        })
      ),
      map((appointmentDto: AppointmentDto) => appointmentActions.createNoteSuccess({ appointmentDto }))
    );
  });
  public createNoteSuccess$ = createEffect(
    () => {
      return this.#action$.pipe(
        ofType(appointmentActions.createNoteSuccess),
        switchMap(() => this.translateMessage$('noteSuccess', null)),
        tap((data: { translate: Record<string, string>; passData: string }) => {
          this.#messageService.add({
            detail: data.translate['appointment.noteSuccess.detail'],
            severity: 'success',
            summary: data.translate['appointment.noteSuccess.summary'],
          });
        })
      );
    },
    { dispatch: false }
  );

  private translateMessage$(
    key: string,
    passData: any
  ): Observable<{
    translate: Record<string, string>;
    passData: any;
  }> {
    return this.#translateService
      .get([`appointment.${key}.detail`, `appointment.${key}.summary`])
      .pipe(map((translate: Record<string, string>) => ({ translate, passData })));
  }
}
