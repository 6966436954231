import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlSegment } from '@angular/router';
import { AccountFacade } from '@fizjo-pro/data-account';
import { ApiAuthService, AuthFacade, CanActivateDto } from '@fizjo-pro/data-auth';
import { AppDomainService, X_TENANT_ID } from '@fizjo-pro/shared/util-app-domain';
import { CookieService } from 'ngx-cookie';
import { catchError, EMPTY, Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private cookies: CookieService,
    private router: Router,
    private authService: ApiAuthService,
    private authFacade: AuthFacade,
    private appDomainService: AppDomainService,
    private accountFacade: AccountFacade
  ) {}

  public canActivate(snapshot: ActivatedRouteSnapshot): Observable<boolean> {
    const path: string = snapshot.url.map((segment: UrlSegment) => segment.path).join('_');

    return this.authService.authControllerCanActivate({ [X_TENANT_ID]: this.appDomainService.tenantId, path }).pipe(
      tap((canActivate: CanActivateDto) => {
        this.authFacade.setAuthenticated({ token: canActivate.token });

        this.accountFacade.loadAccount();
      }),
      map((canActivate: CanActivateDto) => canActivate.canActivate),
      catchError(() => {
        this.router.navigate(['/auth']);
        this.authFacade.setUnauthenticated('auth-guard');

        return EMPTY;
      })
    );
  }
}
