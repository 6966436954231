<ng-container *ngIf="authenticated() && allMenuItems().length">
  <div *ngIf="hasExpiredLicenseBannerVisible()" class="flex justify-content-center no-license-container py-2">
    <span>{{ 'expiredLicenseTopBar.expiredLicenseText' | translate }}</span
    >&nbsp;<a [routerLink]="'products'" class="products-link"
      ><span> {{ 'expiredLicenseTopBar.renewText' | translate }}</span></a
    >
  </div>
  <p-menubar [model]="allMenuItems()" styleClass="px-4">
    <ng-template pTemplate="end">
      <div class="flex flex-row align-items-center gap-4">
        <fizjo-pro-messages-notification></fizjo-pro-messages-notification>
        <ng-container *ngIf="initials()">
          <fizjo-pro-switch-app-user></fizjo-pro-switch-app-user>
        </ng-container>
      </div>
    </ng-template>
  </p-menubar>
</ng-container>
<div class="app-layout">
  <ng-content></ng-content>
</div>
