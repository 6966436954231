/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccountDto } from '../models/account-dto';

@Injectable({
  providedIn: 'root',
})
export class AccountService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation accountControllerReadOne
   */
  static readonly AccountControllerReadOnePath = '/api/account';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountControllerReadOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountControllerReadOne$Response(params?: { context?: HttpContext }): Observable<StrictHttpResponse<AccountDto>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountControllerReadOnePath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AccountDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `accountControllerReadOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountControllerReadOne(params?: { context?: HttpContext }): Observable<AccountDto> {
    return this.accountControllerReadOne$Response(params).pipe(
      map((r: StrictHttpResponse<AccountDto>) => r.body as AccountDto)
    );
  }

  /**
   * Path part for operation accountControllerReadTenantId
   */
  static readonly AccountControllerReadTenantIdPath = '/api/account/{dbName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountControllerReadTenantId()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountControllerReadTenantId$Response(params: {
    /**
     * The account human readable name
     */
    dbName: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountControllerReadTenantIdPath, 'get');
    if (params) {
      rb.path('dbName', params.dbName, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `accountControllerReadTenantId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountControllerReadTenantId(params: {
    /**
     * The account human readable name
     */
    dbName: string;
    context?: HttpContext;
  }): Observable<void> {
    return this.accountControllerReadTenantId$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }
}
