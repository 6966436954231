import { inject, Injectable } from '@angular/core';
import { AppDomainService, X_TENANT_ID } from '@fizjo-pro/shared/util-app-domain';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DateTime } from 'luxon';
import { MessageService } from 'primeng/api';
import { map, Observable, switchMap, tap } from 'rxjs';

import { CalendarActions } from './calendar.actions';
import { CalendarEventDto } from '../api/models/calendar-event-dto';
import { CalendarService } from '../api/services/calendar.service';

@Injectable()
export class CalendarEffects {
  #action$: Actions = inject(Actions);
  #calendarService: CalendarService = inject(CalendarService);
  #messageService: MessageService = inject(MessageService);
  #appDomainService: AppDomainService = inject(AppDomainService);

  public fetch$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(CalendarActions.fetch),
      switchMap(action =>
        this.#calendarService.calendarEventControllerReadMany({
          [X_TENANT_ID]: this.#appDomainService.tenantId,
          end: action.end,
          start: action.start,
        })
      ),
      map((events: CalendarEventDto[]) => CalendarActions.fetchSuccess({ events }))
    );
  });
  public create$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(CalendarActions.create),
      switchMap(action =>
        this.#calendarService.calendarEventControllerCreate({
          body: action.payload,
          [X_TENANT_ID]: this.#appDomainService.tenantId,
        })
      ),
      tap(() => {
        this.#messageService.add({
          detail: 'Utworzono nowe wydarzenie',
          severity: 'success',
          summary: 'Wydarzenie w kalendarzu',
        });
      }),
      map((calendarEvent: CalendarEventDto) => CalendarActions.createSuccess({ calendarEvent }))
    );
  });
  public delete$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(CalendarActions.delete),
      switchMap(action => this.#delete$(action.eventId)),
      tap(() => {
        this.#messageService.add({
          detail: 'Usunięto wydarzenie',
          severity: 'success',
          summary: 'Wydarzenie w kalendarzu',
        });
      }),
      map((eventId: string) => CalendarActions.deleteSuccess({ eventId }))
    );
  });
  public update$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(CalendarActions.update),
      switchMap(action =>
        this.#calendarService.calendarEventControllerUpdate({
          body: action.payload,
          eventId: action.eventId,
        })
      ),
      map((calendarEvent: CalendarEventDto) => CalendarActions.updateSuccess({ calendarEvent }))
    );
  });
  public updateSuccess$ = createEffect(
    () => {
      return this.#action$.pipe(
        ofType(CalendarActions.updateSuccess),
        tap(action => {
          this.#messageService.add({
            detail: `Zmodyfikowano wydarzenie z dnia ${DateTime.fromISO(action.calendarEvent.start).toLocaleString(
              DateTime.DATE_MED
            )}`,
            severity: 'success',
            summary: 'Wydarzenie w kalendarzu',
          });
        })
      );
    },
    { dispatch: false }
  );

  #delete$(eventId: string): Observable<string> {
    return this.#calendarService
      .calendarEventControllerDelete({
        eventId,
      })
      .pipe(map(() => eventId));
  }
}
