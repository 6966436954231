import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppDomainService, X_TENANT_ID } from '@fizjo-pro/shared/util-app-domain';
import { Observable } from 'rxjs';

import { UserService } from './api/services/user.service';

@Injectable()
export class UserFacade {
  #destroyRef: DestroyRef = inject(DestroyRef);
  constructor(
    private userService: UserService,
    private appDomainService: AppDomainService
  ) {}

  public requestVerificationCode$(email: string): Observable<Record<string, string>> {
    return this.userService.passwordControllerRequestMfaCode({
      email,
      [X_TENANT_ID]: this.appDomainService.tenantId,
    });
  }

  public checkEmail$(email: string): Observable<boolean> {
    return this.userService
      .userControllerCheckEmail({ [X_TENANT_ID]: this.appDomainService.tenantId, email })
      .pipe(takeUntilDestroyed(this.#destroyRef));
  }

  public submitPasswordChange$(code: string, email: string, password: string): Observable<void> {
    return this.userService.passwordControllerPatchPassword({
      body: {
        code: code.replace(' ', '') as string,
        email,
        password,
      },
    });
  }
}
