import { inject, Injectable } from '@angular/core';
import { AppDomainService, X_TENANT_ID } from '@fizjo-pro/shared/util-app-domain';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { switchMap } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { gdprActions } from './gdpr.actions';
import { GdprSettingsDto } from '../api/models/gdpr-settings-dto';
import { GdprService } from '../api/services/gdpr.service';

@Injectable()
export class GdprEffects {
  #appDomainService: AppDomainService = inject(AppDomainService);
  #translateService: TranslateService = inject(TranslateService);
  #tenantIdParams: { 'x-tenant-id': string } = {
    [X_TENANT_ID]: this.#appDomainService.tenantId,
  };
  #action$: Actions = inject(Actions);
  #gdprService: GdprService = inject(GdprService);
  #messageService: MessageService = inject(MessageService);

  public read$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(gdprActions.read),
      switchMap(() => this.#gdprService.gdprSettingsControllerReadOne(this.#tenantIdParams)),
      map((gdprSettings: GdprSettingsDto) => gdprActions.readSuccess({ gdprSettings }))
    );
  });
  public update$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(gdprActions.update),
      switchMap(action =>
        this.#gdprService.gdprSettingsControllerUpdateSingle({
          body: action.payload,
          ...this.#tenantIdParams,
        })
      ),
      map((gdprSettings: GdprSettingsDto) => gdprActions.updateSuccess({ gdprSettings }))
    );
  });
  public updateSuccess$ = createEffect(
    () => {
      return this.#action$.pipe(
        ofType(gdprActions.updateSuccess),
        switchMap(() => this.#translateService.get('gdpr.updateGdprSettingsSuccess')),
        tap((summary: string) => {
          this.#messageService.add({
            severity: 'success',
            summary,
          });
        })
      );
    },
    { dispatch: false }
  );
}
