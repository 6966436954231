import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { GDPR_DATA_STORE_KEY } from './gdpr.model';
import { GdprSettingsDto } from '../api/models/gdpr-settings-dto';
import { GdprSettingsPayloadSingleDto } from '../api/models/gdpr-settings-payload-single-dto';

export const gdprActions = createActionGroup({
  events: {
    read: emptyProps(),
    readSuccess: props<{ gdprSettings: GdprSettingsDto | null }>(),
    update: props<{ payload: GdprSettingsPayloadSingleDto }>(),
    updateSuccess: props<{ gdprSettings: GdprSettingsDto }>(),
  },
  source: GDPR_DATA_STORE_KEY,
});
