import { clearState } from '@fizjo-pro/data-auth';
import { createReducer, on } from '@ngrx/store';

import { appConfigActions as actions, contactPointActions } from './app-config.actions';
import { AppConfigState } from './app-config.model';

export const initialState: AppConfigState = {
  _id: '',
  addresses: [],
  companyName: 'fizjo.pro',
  contactPoint: [],
  createdAt: '',
  feats: [],
  initialized: false,
  logoUrl: '',
  tenantId: '',
  permissionMatrix: null,
};
export const appConfigReducers = createReducer(
  initialState,
  on(actions.fetchAppConfigSuccess, (state: AppConfigState, action) => ({
    ...state,
    ...action.appConfig,
  })),
  on(actions.saveAddressSuccess, (state: AppConfigState, action) => ({
    ...state,
    addresses: action.addresses,
  })),
  on(actions.saveCompanyNameSuccess, (state: AppConfigState, action) => ({
    ...state,
    companyName: action.companyName,
  })),
  on(contactPointActions.createContactPointSuccess, (state: AppConfigState, action) => ({
    ...state,
    contactPoint: [...state.contactPoint, action.contactPoint],
  })),
  on(contactPointActions.updateContactPointSuccess, (state: AppConfigState, action) => ({
    ...state,
    contactPoint: action.contactPoints,
  })),
  on(contactPointActions.deleteContactPointSuccess, (state: AppConfigState, action) => ({
    ...state,
    contactPoint: action.contactPoints,
  })),
  on(actions.fetchPermissionMatrixSuccess, (state: AppConfigState, action) => ({
    ...state,
    permissionMatrix: action.permissionMatrix,
  })),
  on(clearState, () => initialState)
);
