import { Injectable } from '@angular/core';
import { AppDomainService } from '@fizjo-pro/shared/util-app-domain';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

import { AddressDto } from './api/models/address-dto';
import { AddressPayloadDto } from './api/models/address-payload-dto';
import { AppConfigDto } from './api/models/app-config-dto';
import { AppFeat } from './api/models/app-feat';
import { CompanyNamePayloadDto } from './api/models/company-name-payload-dto';
import { ConfigService } from './api/services/config.service';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  public readonly appConfig$: Observable<AppConfigDto>;

  public readonly companyName$!: Observable<string>;
  private appConfigSubj$: BehaviorSubject<AppConfigDto> = new BehaviorSubject<AppConfigDto>({
    _id: '',
    addresses: [],
    companyName: 'fizjo.pro',
    contactPoint: [],
    createdAt: '',
    feats: [],
    initialized: false,
    logoUrl: '',
    tenantId: '',
  });

  constructor(
    private api: ConfigService,
    private appDomainService: AppDomainService
  ) {
    this.appConfig$ = this.appConfigSubj$.asObservable();
    this.companyName$ = this.appConfig$.pipe(map((appConfig: AppConfigDto) => appConfig.companyName));
    this.init();
  }

  public init(): void {
    this.api
      .appConfigUnsecureControllerReadConfig({ tenantId: this.appDomainService.tenantId })
      .subscribe((appConfig: AppConfigDto) => {
        this.appConfigSubj$.next(appConfig);
      });
  }

  public hasFeat$(featName: AppFeat): Observable<boolean> {
    return this.api.featureControllerHasFeat({ featName }).pipe(map(Boolean));
  }

  public saveCompanyName$(companyName: string): Observable<CompanyNamePayloadDto> {
    return this.api
      .appConfigControllerPatchCompanyName({
        body: { companyName },
      })
      .pipe(
        tap(() => {
          this.appConfigSubj$.next({
            ...this.appConfigSubj$.value,
            companyName,
          });
        })
      );
  }

  public createAddress$(payload: AddressPayloadDto): Observable<AddressDto[]> {
    return this.api.addressControllerCreate({ body: payload }).pipe(this.addressResponse$.bind(this));
  }

  public updateAddress$(addressId: string, body: AddressPayloadDto): Observable<AddressDto[]> {
    return this.api.addressControllerUpdate({ addressId, body }).pipe(this.addressResponse$.bind(this));
  }

  public removeAddress$(addressId: string): Observable<AddressDto[]> {
    return this.api.addressControllerDeleteOne({ addressId }).pipe(this.addressResponse$.bind(this));
  }

  public appConfigHandler(appConfig: AppConfigDto): void {
    this.appConfigSubj$.next(appConfig);
  }

  private changeFile$(file: File): Observable<string> {
    const promise: Promise<string> = new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = (): void => resolve(reader.result as string);
      reader.onerror = (error: ProgressEvent<FileReader>): void => reject(error);
    });

    return from(promise);
  }

  private b64toBlob(b64Data: string, contentType = '', sliceSize = 512): Blob {
    const byteCharacters: string = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  private addressResponse$(addresses$: Observable<AddressDto[]>): Observable<AddressDto[]> {
    return addresses$.pipe(
      tap((addresses: AddressDto[]) => {
        this.appConfigSubj$.next({
          ...this.appConfigSubj$.value,
          addresses,
        });
      })
    );
  }
}
