import { inject, Injectable } from '@angular/core';
import { AppDomainService, X_TENANT_ID } from '@fizjo-pro/shared/util-app-domain';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Permission } from './api/models/permission';
import { Role } from './api/models/role';
import { TokenDto } from './api/models/token-dto';
import { UserDto } from './api/models/user-dto';
import { UserPayloadDto } from './api/models/user-payload-dto';
import { AuthService } from './api/services/auth.service';
import { authActions } from './state/auth.actions';
import {
  selectIsAuthenticated,
  selectMyData,
  selectPermissions,
  selectRole,
  selectSessionTimeout,
  selectUserReady,
} from './state/auth.selectors';

@Injectable()
export class AuthFacade {
  #authService: AuthService = inject(AuthService);
  #appDomainService: AppDomainService = inject(AppDomainService);
  #store: Store = inject(Store);

  public authenticated$: Observable<boolean> = this.#store.select(selectIsAuthenticated);
  public userReady$: Observable<boolean> = this.#store.select(selectUserReady);
  public readonly me$: Observable<UserDto | null> = this.#store.select(selectMyData);
  public readonly role$: Observable<Role> = this.#store.select(selectRole);
  public readonly permissions$: Observable<Permission[]> = this.#store.select(selectPermissions);
  public sessionTimeout$: Observable<number> = this.#store.select(selectSessionTimeout);

  public setAuthenticated(tokenDto: TokenDto): void {
    this.#store.dispatch(authActions.setAuthenticated({ tokenDto }));
  }

  public setUnauthenticated(source: string, uid?: string): void {
    this.#store.dispatch(authActions.setUnauthenticated({ uid, source }));
  }

  public resolveOwnData(): void {
    this.#store.dispatch(authActions.fetchOwnUser());
  }

  public updateUserData(uid: string, payload: UserPayloadDto): void {
    this.#store.dispatch(authActions.updateUserData({ payload, uid }));
  }

  public requestMfa$(): Observable<unknown> {
    return this.#authService.authControllerRequestMfa({ [X_TENANT_ID]: this.#appDomainService.tenantId });
  }
}
