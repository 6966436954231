import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AUTH_FEATURE_KEY, AuthState } from './auth.model';
import { Role } from '../api/models';
import { UserDto } from '../api/models/user-dto';

export const selectAuthFeature = createFeatureSelector<AuthState>(AUTH_FEATURE_KEY);

export const selectIsAuthenticated = createSelector(selectAuthFeature, (state: AuthState) => state.authenticated);

export const selectMyData = createSelector(selectAuthFeature, (state: AuthState) => state.user);
export const selectInitials = createSelector(selectMyData, (user: UserDto | null) =>
  user ? `${user.firstName[0]}${user.lastName[0]}` : null
);
//TODO: change after API generation
export const selectRole = createSelector(selectMyData, (user: UserDto | null) =>
  user !== null ? user.role : Role.User
);

//TODO: change after API generation
export const selectPermissions = createSelector(selectMyData, (user: UserDto | null) =>
  user !== null ? user.permissions : []
);

export const selectUserReady = createSelector(selectMyData, (user: UserDto | null) => user !== null);

export const selectScreenLock = createSelector(selectAuthFeature, (state: AuthState) => state.screenLock);

export const selectSessionTimeout = createSelector(selectAuthFeature, (state: AuthState) => state.sessionTimeout);
