import { computed, inject, Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { SettingsItemsService, settingsMenuItems } from '@fizjo-pro/settings/feat-settings';
import { MeService } from '@fizjo-pro/util-auth';
import { AppConfigService, AppFeat } from '@kate-fizjo/practice-shared/app-config';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { MenuItem } from 'primeng/api';

const MENU_ITEMS: MenuItem[] = [
  {
    icon: 'pi pi-home',
    routerLink: '/',
  },
  {
    label: 'menu.appointmentsLabel',
    queryParams: {
      from: DateTime.now().startOf('month').toFormat('yyyy-MM-dd'),
      to: DateTime.now().endOf('month').toFormat('yyyy-MM-dd'),
    },
    routerLink: '/appointments',
  },
  {
    label: 'menu.patients',
    routerLink: '/patients',
  },
  {
    label: 'menu.owners',
    routerLink: '/owners',
  },
  {
    label: 'menu.extensions',
    routerLink: '/products',
  },
  {
    label: 'menu.settingsLabel',
    routerLink: '/settings',
    items: settingsMenuItems,
  },
  {
    label: 'menu.help',
    routerLink: '/messages',
  },
];

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  #meService: MeService = inject(MeService);
  #translateService: TranslateService = inject(TranslateService);
  #settingsItemsService: SettingsItemsService = inject(SettingsItemsService);
  #appConfigService: AppConfigService = inject(AppConfigService);

  #permissions = toSignal(this.#meService.permissions$);
  #settingsItems = toSignal(this.#settingsItemsService.items$);
  #hasFeat: Signal<boolean | undefined> = toSignal(this.#appConfigService.hasFeat$(AppFeat.Marketplace));

  #menuItems = computed(() => {
    return MENU_ITEMS.filter(menuItem => {
      const permission = menuItem.state?.['permission'];

      return permission === undefined || this.#permissions()?.includes(permission);
    }).map(menuItem => ({
      ...menuItem,
      label: menuItem.label?.length ? this.#translateService.instant(menuItem?.label || 'menu.item') : undefined,
    }));
  });

  #menuItemsWithSettings = computed(() => {
    const menuItemsValue = this.#menuItems();
    const settingsItems = this.#settingsItems();

    return menuItemsValue.map(item => {
      return item.routerLink === '/settings' ? { ...item, items: settingsItems } : item;
    });
  });

  public allMenuItems = computed(() => {
    const shouldDisable = !this.#hasFeat();

    return this.#menuItemsWithSettings().map(menuItem => {
      if (menuItem.routerLink === '/products') {
        return {
          ...menuItem,
          disabled: shouldDisable,
        };
      } else {
        return menuItem;
      }
    });
  });
}
