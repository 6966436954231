import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, withLatestFrom } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AccountDto } from './api/models/account-dto';
import { ProductCode } from './api/models/product-code';
import { AccountActions } from './state/account.actions';
import {
  selectAccount,
  selectAccountCurrentLicense,
  selectAccountCurrentLicenseProductCode,
  selectAccountDataValue,
  selectAccountMonthlyAppointmentsValue,
  selectAccountOwnersValue,
  selectAccountPatientsValue,
  selectAccountSmsValue,
  selectAccountUsersValue,
  selectHasValidLicense,
  selectReady,
} from './state/account.selectors';

@Injectable()
export class AccountFacade {
  #store: Store = inject(Store);

  public account$: Observable<AccountDto | null> = this.#store.select(selectAccount);
  selectAccountCurrentLicense$ = this.#store.select(selectAccountCurrentLicense);
  selectAccountCurrentLicenseProductCode$ = this.#store.select(selectAccountCurrentLicenseProductCode);
  selectAccountSmsValue$ = this.#store.select(selectAccountSmsValue);
  selectAccountDataValue$ = this.#store.select(selectAccountDataValue);
  selectAccountUsersValue$ = this.#store.select(selectAccountUsersValue);
  selectAccountMonthlyAppointmentsValue$ = this.#store.select(selectAccountMonthlyAppointmentsValue);
  selectAccountPatientsValue$ = this.#store.select(selectAccountPatientsValue);
  selectAccountOwnersValue$ = this.#store.select(selectAccountOwnersValue);
  selectHasValidLicense$ = this.#store.select(selectHasValidLicense);
  selectReady$ = this.#store.select(selectReady);

  public loadAccount(): void {
    this.#store.dispatch(AccountActions.load());
  }

  public hasValidLicenses$(licenseCodes: ProductCode[]): Observable<boolean> {
    return this.selectAccountCurrentLicenseProductCode$.pipe(
      withLatestFrom(this.selectHasValidLicense$),
      map(([currentLicenseProductCode, isValid]) =>
        currentLicenseProductCode ? licenseCodes.includes(currentLicenseProductCode) && isValid : false
      ),
      filter(Boolean)
    );
  }
}
