import { clearState } from '@fizjo-pro/data-auth';
import { createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { CalendarActions } from './calendar.actions';
import { CalendarEventState } from './calendar.model';
import { CalendarEventDto } from '../api/models/calendar-event-dto';

export const calendarAdapter = createEntityAdapter<CalendarEventDto>({
  selectId: (cEvent: CalendarEventDto) => cEvent._id,
});

export const calendarEventInitialState: CalendarEventState = {
  ...calendarAdapter.getInitialState(),
  ready: false,
};

export const reducer = createReducer(
  calendarEventInitialState,
  on(CalendarActions.fetchSuccess, (state: CalendarEventState, action) => ({
    ...calendarAdapter.upsertMany(action.events, state),
    ready: true,
  })),
  on(CalendarActions.createSuccess, (state: CalendarEventState, action) =>
    calendarAdapter.upsertOne(action.calendarEvent, state)
  ),
  on(CalendarActions.deleteSuccess, (state: CalendarEventState, action) =>
    calendarAdapter.removeOne(action.eventId, state)
  ),
  on(CalendarActions.updateSuccess, (state: CalendarEventState, action) =>
    calendarAdapter.updateOne(
      {
        changes: action.calendarEvent,
        id: action.calendarEvent._id,
      },
      state
    )
  ),
  on(clearState, () => calendarEventInitialState)
);

export function calendarReducer(state: CalendarEventState | undefined, action: Action): CalendarEventState {
  return reducer(state, action);
}

export const { selectAll } = calendarAdapter.getSelectors();
